import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { BusinessFrontId, businessFronts } from "./business-front.data";

@Injectable({
  providedIn: "root",
})
export class BusinessFrontService {
  readonly current = new BehaviorSubject(businessFronts.vss);

  private renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  getBusinessFront() {
    return this.current;
  }

  setBusinessFront(businessFrontId: BusinessFrontId) {
    this.current.next(businessFronts[businessFrontId]);
    this.renderer.setAttribute(this.document.body, "data-business-front", businessFrontId);
  }
}
