export enum FontSize {
  default = 16,
  lg = 18,
  md = 15,
  sm = 14,
  xsm = 13,
  xxsm = 12,
}

export type FontSizeOption = "xxsm" | "xsm" | "sm" | "md" | "default" | "lg";

export type LineHeight = "135%" | "150%";

export type Weight = "400" | "500" | "600" | "700";
