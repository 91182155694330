import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, Router } from "@angular/router";
import { forkJoin, map, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { GeneralService } from "@shared/general/general.service";
import { BudgetItemRead, Unit } from "@shared/general/general.models";
import { BudgetDataRead } from "@modules/budgets/budget-show/budget-show.models";
import { LoadingService } from "@shared/services/loading/loading.service";
import { AnalyticsService } from "@shared/services/analytics/analytics.service";

const sendAnalyticsEvents = (err: any) => {
  AnalyticsService.logEvent({
    name: "exception",
    params: {
      description: `${err.code}:orcamento-indisponivel`,
      fatal: false,
    },
  });
};

export const budgetResolver: ResolveFn<[BudgetItemRead, BudgetDataRead]> = (
  route: ActivatedRouteSnapshot,
) => {
  const router = inject(Router);
  const loadingService: LoadingService = inject(LoadingService);

  const id = route.paramMap.get("id");

  const mobile$ = inject(GeneralService<{ data: BudgetItemRead }>)
    .getById(`quotations/${id}`!)
    .pipe(
      map((res) => {
        const isAllSpecialtiesAvailable = res.data.units.map((unit: Unit) =>
          unit.items.every((item) => item.is_available),
        );

        res.data.isAllSpecialtiesAvailable = isAllSpecialtiesAvailable.every(
          (isAvailable: boolean) => isAvailable,
        );

        return res;
      }),
    );

  const desktop$ = inject(GeneralService<{ data: BudgetDataRead }>).getById(
    `quotations/${id}/desktop`!,
  );

  return forkJoin([mobile$, desktop$]).pipe(
    catchError((err) => {
      sendAnalyticsEvents(err);

      router.navigate(["/erro"], {
        queryParams: { ...err, d: new Date().getTime(), step: "cliente-vivo:lp-orcamento" },
      });

      loadingService.dismissAll();

      return throwError(() => err);
    }),
  );
};
