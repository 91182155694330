export type ImageByBusinessFrontPart = "logo" | "logoLight" | "icon" | "error" | "spinner";

type ImageByBusinessFrontPartInfo = {
  path: string;
  alt: string;
  hasBusinessFrontName?: boolean;
};

export type ImageByBusinessFrontInfo = {
  [keyPart in ImageByBusinessFrontPart]: ImageByBusinessFrontPartInfo;
};

export const imagesForBusinessFront: ImageByBusinessFrontInfo = {
  logo: {
    path: "logo.svg",
    alt: "Logo",
    hasBusinessFrontName: true,
  },
  logoLight: {
    path: "logo-modo-escuro.svg",
    alt: "Logo",
    hasBusinessFrontName: true,
  },
  icon: {
    path: "logo-icone.svg",
    alt: "Logo",
    hasBusinessFrontName: true,
  },
  error: {
    path: "lupa-erro.svg",
    alt: "Ícone de lupa - Simbolizando que não encontrou nada",
  },
  spinner: {
    path: "loading-spinner.png",
    alt: "",
  },
};
