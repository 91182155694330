import { CommonModule } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { ImageByBusinessFrontDirective } from "@shared/directives/image-by-businnes-front/image-by-business-front.directive";

import { SpacerComponent } from "../spacer/spacer.component";
import { SpinnerLogoComponent } from "../spinners/spinner-logo/spinner-logo.component";
import { TextComponent } from "../typography/text/text.component";

export type LoadingProps = {
  description: string;
};

const imports = [
  CommonModule,
  SpacerComponent,
  TextComponent,
  SpinnerLogoComponent,
  ImageByBusinessFrontDirective,
];
@Component({
  standalone: true,
  selector: "app-loading",
  templateUrl: "./loading.component.html",
  styleUrls: ["./loading.component.scss"],
  imports,
})
export class LoadingComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LoadingProps,
    public dialogRef: MatDialogRef<LoadingComponent>,
  ) {}
}
