import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { budgetResolver } from "@data/resolvers/budget/budget-resolver";
import { budgetGuard } from "@core/guards/budget/budget.guard";

const routes: Routes = [
  {
    path: "",
    data: {
      pagePath: "/redirect-stores",
    },
    loadComponent: () =>
      import("@modules/home/home-page.component").then((m) => m.HomePageComponent),
  },
  {
    path: "orcamentos/:id",
    resolve: { budget: budgetResolver },
    canActivate: [budgetGuard],
    loadComponent: () =>
      import("./modules/budgets/budget-show/budget-show.component").then(
        (m) => m.BudgetShowComponent,
      ),
  },
  {
    path: "erro",
    loadComponent: () =>
      import("@modules/error/error-page.component").then((m) => m.ErrorPageComponent),
  },

  // Non handled routes
  {
    path: "**",
    redirectTo: "/",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
