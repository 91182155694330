import { defaultContact } from "@app/data/static/data";

export type Contact = {
  html: string;
  display: string;
};

export type BusinessFrontId = "vss" | "midway";

export type BusinessFrontContact = {
  phone: Contact;
  whatsapp: Contact;
};

export type BusinessFront = {
  id: BusinessFrontId;
  name: string;
  contact: BusinessFrontContact;
  cardName: string;
  appName: string;
};

export type BusinessFrontInfo = {
  [key in BusinessFrontId]: BusinessFront;
};

export const businessFronts: BusinessFrontInfo = {
  vss: {
    id: "vss",
    name: "Vale Saúde Sempre",
    contact: defaultContact,
    cardName: "Vale Saúde Sempre",
    appName: "Vale Saúde Sempre",
  },
  midway: {
    id: "midway",
    name: "Midway Riachuelo",
    contact: defaultContact,
    cardName: "Mais Saúde",
    appName: "Vale Saúde Sempre",
  },
};
