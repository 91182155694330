import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { LoadingComponent, LoadingProps } from "@shared/components/loading/loading.component";

@Injectable({
  providedIn: "root",
})
export class LoadingService {
  constructor(private dialog: MatDialog) {}

  present(data: LoadingProps) {
    this.dialog.open(LoadingComponent, {
      height: "100%",
      width: "100%",
      maxWidth: "100%",
      disableClose: true,
      autoFocus: false,
      data,
    });
  }

  dismissAll() {
    this.dialog.closeAll();
  }
}
