import { Injectable } from "@angular/core";

import { RouteService } from "@shared/services/route/route.service";
import { PlatformService } from "@shared/services/platform/platform.service";
import { defaultAnalyticsPageParams } from "@shared/services/analytics/analytics.models";
import { AnalyticsWrapperService } from "@shared/services/analytics/analytics-wrapper/analytics-wrapper.service";

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  isIOS: boolean;

  constructor(
    private routeService: RouteService,
    private platformService: PlatformService,
  ) {
    this.isIOS = platformService.is("ios");
  }

  initScreenViewRouting() {
    this.routeService.getRouteDataOnRouteChange(this.sendScreenView);
  }

  sendScreenView = (data?: { pagePath?: string }) => {
    const isMobile = this.platformService.getPlatform() === "mobile";
    const platformName = this.platformService.getPlatformName();

    AnalyticsService.logEvent({
      name: "page_view",
      params: {
        page: {
          ...defaultAnalyticsPageParams,
          pagePath: data?.pagePath || location.pathname,
          environment: this.platformService.getPlatform(),
          platform: isMobile ? platformName : "web",
        },
      },
    });
  };

  static logEvent(options: { name: string; params: object }) {
    if (!options.name) {
      return;
    }

    AnalyticsWrapperService.logEvent(options);
  }
}
