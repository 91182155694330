import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";

import { BusinessFrontId } from "@core/services/business-front/business-front.data";
import { ImageByBusinessFrontDirective } from "@shared/directives/image-by-businnes-front/image-by-business-front.directive";

@Component({
  standalone: true,
  selector: "app-spinner-logo",
  templateUrl: "./spinner-logo.component.html",
  styleUrls: ["./spinner-logo.component.scss"],
  imports: [CommonModule, ImageByBusinessFrontDirective],
})
export class SpinnerLogoComponent {
  @Input() businessFront: BusinessFrontId = "vss";
}
