import { Injectable } from "@angular/core";

export interface WindowAnalytics extends Window {
  dataLayer: Record<string, unknown>[];
}

declare const window: WindowAnalytics;

@Injectable({
  providedIn: "root",
})
export class AnalyticsWrapperService {
  static logEvent(options: { name: string; params: object }) {
    const obj = {
      event: options.name,
      ...options.params,
    };

    window.dataLayer.push(obj);
  }
}
