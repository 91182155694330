import { CanActivateFn } from "@angular/router";
import { inject } from "@angular/core";

import { LoadingService } from "@shared/services/loading/loading.service";

export const budgetGuard: CanActivateFn = () => {
  const loadingService: LoadingService = inject(LoadingService);
  loadingService.present({ description: "Aguarde enquanto carregamos o orçamento..." });
  return true;
};
