import { ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, provideHttpClient, withInterceptors } from "@angular/common/http";
import { registerLocaleData } from "@angular/common";
import localePt from "@angular/common/locales/pt";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { BugsnagErrorHandler } from "@bugsnag/plugin-angular";
import BugsnagPerformance from "@bugsnag/browser-performance";
import Bugsnag from "@bugsnag/js";

import { errorInterceptor } from "@core/interceptors/error/error.interceptor";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { environment } from "../environments/environment";

if (environment.BUGSNAG_KEY) {
  Bugsnag.start({ apiKey: environment.BUGSNAG_KEY });
  BugsnagPerformance.start({ apiKey: environment.BUGSNAG_KEY });
}

registerLocaleData(localePt);

export function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MatDialogModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  providers: [
    { provide: "Window", useValue: window },
    { provide: LOCALE_ID, useValue: "pt" },
    provideHttpClient(withInterceptors([errorInterceptor])),
    provideNgxMask(),
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
