import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";

import { FontSize, FontSizeOption, LineHeight, Weight } from "./text.data";

@Component({
  standalone: true,
  selector: "app-text",
  templateUrl: "./text.component.html",
  styleUrls: ["./text.component.scss"],
  imports: [CommonModule],
})
export class TextComponent implements OnInit {
  @Input() color = "dark-tint";

  @Input() size: FontSizeOption = "default";

  @Input() sizeDesktop: FontSizeOption | undefined = undefined;

  @Input() lineHeight: LineHeight = "150%";

  @Input() weight: Weight = "400";

  fontSize = 1;

  fontSizeDesktop: number | undefined = undefined;

  fontSizeEnum = FontSize;

  ngOnInit(): void {
    this.setFontSize();
  }

  setFontSize(): void {
    this.fontSize = FontSize[this.size] / 16;

    if (this.sizeDesktop) {
      this.fontSizeDesktop = FontSize[this.sizeDesktop] / 16;
    }
  }
}
