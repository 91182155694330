<span
  [ngStyle]="{
    '--text-font-size.rem': fontSize,
    '--text-font-size-desktop.rem': fontSizeDesktop || fontSize,
    color: 'var(--color-' + color + ')',
    'line-height': lineHeight,
    'font-weight': weight,
  }"
>
  <ng-content></ng-content>
</span>
