import { HttpParams } from "@angular/common/http";

export const constructApiVersionQueryParams = (inputParams: HttpParams | undefined): HttpParams => {
  if (inputParams && inputParams.keys().length > 0) {
    // inputParams = inputParams.append('apiVersion', apiVersion);
    return inputParams;
  }
  // const params = new HttpParams().set('version', apiVersion);
  const params = new HttpParams();
  return params;
};
