import { environment } from "../../../../environments/environment";

/**
 * @description
 * Se houver algum prefixo para todas as APIs, defina-o(s) em Constants.
 */
export class Constants {
  public static apiRoot = environment.urlApi;

  public static API_VERSIONS = {
    Version1: "v1",
  };
}
