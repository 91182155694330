import { HttpErrorResponse } from "@angular/common/http";

export const handleError = (
  err: HttpErrorResponse,
): {
  message: string;
  code: string;
} => {
  const { message = "Erro ao realizar requisição. Tente novamente!", code = "" } =
    err?.error?.error || err.error;

  return { message, code };
};
