import { Injectable } from "@angular/core";

export type PlatformName = "windows" | "android" | "ios";
export type PlatformType = "mobile" | "web";

interface WindowCustom extends Window {
  MSStream: any;
  opera: any;
}

declare const window: WindowCustom;

@Injectable({
  providedIn: "root",
})
export class PlatformService {
  is(name: PlatformName): boolean {
    return this.getPlatformName() === name;
  }

  getPlatformName(): PlatformName | undefined {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "windows";
    }

    if (/android/i.test(userAgent)) {
      return "android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "ios";
    }

    return undefined;
  }

  getPlatform(): PlatformType {
    return this.isMobile() ? "mobile" : "web";
  }

  private isMobile(): boolean {
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(navigator.userAgent);
  }
}
