import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import urljoin from "url-join";

import { HttpOptions } from "./base.models";
import { constructApiVersionQueryParams } from "./construct";
import { Constants } from "./constants";

/**
 * @description
 * Serviço genérico.
 * Assume que todas as APIs chamadas são versionadas a partir de parâmetros de query, resultando em:
 * http://localhost:3000/version/todoItem
 * http://localhost:3000/version/todoItem/1
 */
export abstract class BaseService<TRead, TCreate, TUpdate> {
  private readonly endpointUrl: string;

  protected constructor(
    private readonly http: HttpClient,
    private readonly path: string,
    private readonly apiVersion: string,
    private readonly apiRoot?: string,
  ) {
    this.endpointUrl = urljoin(apiRoot || Constants.apiRoot, apiVersion, this.path);
  }

  /**
   * @description
   * Listagem genérica de itens.
   * @param options Dados extras.
   */
  get(options: HttpOptions = {}): Observable<TRead[]> {
    options.params = constructApiVersionQueryParams(options.params);
    return this.http.get<TRead[]>(this.endpointUrl, options).pipe(catchError(this.formatErrors));
  }

  /**
   * @description
   * Listagem genérica por ID.
   *
   * @param id      ID obrigatório.
   * @param options Dados extras.
   */
  getById(id: string, options: HttpOptions = {}): Observable<TRead> {
    options.params = constructApiVersionQueryParams(options.params);
    const url = urljoin(this.endpointUrl, id);
    return this.http.get<TRead>(url, options).pipe(catchError(this.formatErrors));
  }

  /**
   * @description
   * Criação genérica.
   * @param creationBody  Informações do corpo da requisição.
   * @param options       Dados extras.
   */
  create(creationBody: TCreate, options: HttpOptions = {}): Observable<TRead> {
    options.params = constructApiVersionQueryParams(options.params);
    return this.http
      .post<TRead>(this.endpointUrl, creationBody, options)
      .pipe(catchError(this.formatErrors));
  }

  /**
   * @description
   * Atualização genérica por ID.
   *
   * @param id          ID obrigatório.
   * @param updateBody  Informações do corpo da requisição.
   * @param options     Dados extras.
   */
  update(id: string, updateBody: TUpdate, options: HttpOptions = {}): Observable<any> {
    options.params = constructApiVersionQueryParams(options.params);
    const url = urljoin(this.endpointUrl, id);
    return this.http.put(url, updateBody, options).pipe(catchError(this.formatErrors));
  }

  /**
   * @description
   * Atualização genérica por ID.
   *
   * @param id          ID obrigatório.
   * @param updateBody  Informações do corpo da requisição.
   * @param options     Dados extras.
   */
  patch(id: string, updateBody: TUpdate, options: HttpOptions = {}): Observable<any> {
    options.params = constructApiVersionQueryParams(options.params);
    const url = urljoin(this.endpointUrl, id);
    return this.http.patch(url, updateBody, options).pipe(catchError(this.formatErrors));
  }

  /**
   * @description
   * Deleção genérica por ID.
   *
   * @param id      ID obrigatório.
   * @param options Dados extras.
   */
  delete(id: string, options: HttpOptions = {}): Observable<any> {
    options.params = constructApiVersionQueryParams(options.params);
    const url = urljoin(this.endpointUrl, id);
    return this.http.delete(url, options).pipe(catchError(this.formatErrors));
  }

  protected formatErrors(error: any): Observable<never> {
    return throwError(() => error);
  }
}
