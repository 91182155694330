import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { BaseService } from "@shared/services/base/base.service";
import { Constants } from "@shared/services/base/constants";

@Injectable({
  providedIn: "root",
})
export class GeneralService<TRead> extends BaseService<TRead, never, never> {
  constructor(httpClient: HttpClient) {
    super(httpClient, "general", Constants.API_VERSIONS.Version1);
  }
}
