import { Directive, ElementRef, Input, OnInit } from "@angular/core";

import { BusinessFront, businessFronts } from "@core/services/business-front/business-front.data";
import { BusinessFrontService } from "@core/services/business-front/business-front.service";

import {
  ImageByBusinessFrontInfo,
  ImageByBusinessFrontPart,
  imagesForBusinessFront,
} from "./image-by-business-front.data";

@Directive({
  selector: "[appImageByBusinessFront]",
  standalone: true,
})
export class ImageByBusinessFrontDirective implements OnInit {
  @Input() part: ImageByBusinessFrontPart | undefined;

  businessFront = businessFronts.vss;

  constructor(
    private img: ElementRef,
    private businessFrontService: BusinessFrontService,
  ) {}

  ngOnInit(): void {
    this.setBusinessFrontAndImage();
  }

  setImagesAttributes(businessFront: BusinessFront) {
    const imageInfo: ImageByBusinessFrontInfo = imagesForBusinessFront;

    if (!this.part || !imageInfo) {
      throw new Error("Part or image attribute must be defined");
    }

    this.img.nativeElement.setAttribute(
      "src",
      `/assets/business-front/${businessFront.id}/${imageInfo[this.part]?.path}`,
    );

    const alt = imageInfo[this.part]?.hasBusinessFrontName
      ? `${imageInfo[this.part]?.alt} ${businessFront.name}`
      : imageInfo[this.part]?.alt;

    this.img.nativeElement.setAttribute("alt", alt);
  }

  setBusinessFrontAndImage(): void {
    const current = this.businessFrontService.getBusinessFront().getValue();
    this.setImagesAttributes(current);
  }
}
