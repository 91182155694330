import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";

export type Theme = "indigo-bliss" | "tropical-azure" | "mint-delight";

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  readonly renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  setTheme(themeName: Theme): void {
    this.renderer.setAttribute(this.document.body, "data-theme", themeName);
  }
}
