import { BusinessFrontContact } from "@app/core/services/business-front/business-front.data";

export const appStore = "https://apps.apple.com/br/app/vale-sa%C3%BAde-sempre/id1473828335";
export const playStore =
  "https://play.google.com/store/apps/details?id=cliente.valesaudesempre.com.br";
export const website = "https://app.valesaudesempre.com.br";

export const defaultContact: BusinessFrontContact = {
  phone: {
    html: "30030256",
    display: "3003 0256",
  },
  whatsapp: {
    html: "551130030256",
    display: "(11) 3003 0256",
  },
};
