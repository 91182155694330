<section class="loading">
  <div class="loading__content">
    <app-spinner-logo></app-spinner-logo>

    <ng-container *ngIf="data?.description as description">
      <app-spacer [mobile]="24" [desktop]="32"></app-spacer>

      <app-text weight="400" sizeDesktop="lg">
        <p>{{ description }}</p>
      </app-text>
    </ng-container>
  </div>
</section>
