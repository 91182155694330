<div class="spinner-logo">
  <img
    class="spinner-logo__spinner"
    appImageByBusinessFront
    part="spinner"
    loading="lazy"
    width="88"
    height="88"
    aria-hidden="true"
  />

  <img
    *ngIf="businessFront"
    class="spinner-logo__logo"
    appImageByBusinessFront
    part="icon"
    loading="lazy"
    width="38"
    height="32"
    aria-hidden="true"
  />
</div>
